import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTable } from '../common/RadTable'
import { RadTextFilter } from '../common/RadTextFilter'
import { RadDatePicker } from '../common/RadDatePicker'
import { formatDate, humanReadableId, isValidDate, numberWithCommas } from '../common/utilities'

export function SessionList ({ selected }) {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedUserOption, setSelectedUserOption] = useState()
  const [userFilterText, setUserFilterText] = useState('')
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const programId = searchParams.get('programId') ?? ''
  const schoolYear = searchParams.get('schoolYear') ?? ''
  const serviceId = searchParams.get('indicatorId') ?? ''
  const schoolId = searchParams.get('schoolId') ?? ''
  const grade = searchParams.get('grade') ?? ''
  const from = searchParams.get('from') ?? ''
  const to = searchParams.get('to') ?? ''
  const { data: sessions, count } = useGet(
    selectedUserOption != null
      ? `/api/session?search=${searchText}` +
      `&schoolYear=${schoolYear}` +
      `&from=${isValidDate(from) ? from : ''}` +
      `&to=${isValidDate(to) ? to : ''}` +
      `&programId=${programId}` +
      `&serviceId=${serviceId}` +
      `&administeredById=${selectedUserOption?.value !== '0' ? selectedUserOption?.value : ''}` +
      `&tierId=${searchParams.get('tier') ?? ''}` +
      `&schoolId=${schoolId}` +
      `&grade=${grade}` +
      `&limit=${pageLength}` +
      `&offset=${(currentPageIndex - 1) * pageLength}`
      : null
  )
  const { data: userInfo } = useGet('/api/user/current')
  const { data: schoolYearOptions } = useGet('/api/option/school-year')
  const { data: gradeOptions } = useGet('/api/option/grade?any=true')
  const { data: programOptions } = useGet('/api/option/program?any=true')
  const { data: schoolOptions } = useGet('/api/option/school?any=true')
  const { data: serviceOptions } = useGet('/api/option/service?required=true&any=true')
  const { data: tierOptions } = useGet('/api/option/tier?any=true')
  const { data: userOptions } = useGet(`/api/option/user?search=${userFilterText}&required=true&includeDeleted=true`)
  const { data: administeredBy } = useGet(searchParams.get('administeredBy') !== null && parseInt(searchParams.get('administeredBy')) > 1 ? `/api/user/${searchParams.get('administeredBy')}` : null)

  useEffect(() => {
    if (userInfo != null && searchParams.get('administeredBy') == null) {
      setSelectedUserOption({ value: userInfo.id.toString(), label: userInfo.name })
    }
    if (selectedUserOption == null && searchParams.get('administeredBy') != null && administeredBy != null) {
      setSelectedUserOption({ value: administeredBy.id.toString(), label: administeredBy.name.replace('Migration', 'Auto-generated') })
    }
    if (selectedUserOption == null && searchParams.get('administeredBy') === '0') {
      setSelectedUserOption({ value: '0', label: 'Any administrator' })
    }
  }, [userInfo, administeredBy, serviceOptions])

  useEffect(() => {
    if (userOptions != null) {
      userOptions.unshift({ value: '0', label: 'Any administrator' })
    }
  }, [userOptions])

  if (
    sessions != null &&
    schoolYearOptions != null &&
    gradeOptions != null &&
    programOptions != null &&
    selectedUserOption != null &&
    schoolOptions != null &&
    userOptions != null &&
    serviceOptions != null &&
    tierOptions != null
  ) {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()
    const defaultSchoolYear = currentMonth < 6 ? currentYear - 1 : currentYear
    const selectedSchoolYearOption = schoolYearOptions.find((x) => x.value === schoolYear) ?? schoolYearOptions.find((x) => x.value === defaultSchoolYear.toString())

    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/session/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + numberWithCommas(count) + ')'}
          >
            Sessions
          </RadHeader>
        }
        content={
          <RadCards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionGroupLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item => (
                <RadLink fontSize='heading-l' href={`/session/${humanReadableId(item.id)}`}>{humanReadableId(item.id)}</RadLink>
              ),
              sections: [
                {
                  id: 'date',
                  header: 'Date',
                  content: item => formatDate(item.date)
                },
                {
                  id: 'administeredBy',
                  header: 'Administered By',
                  content: item => <RadLink href={`/user/${item.owner.id}`}>{item.owner.name}</RadLink>
                },
                {
                  id: 'description',
                  header: 'Description',
                  content: item => item.description ?? '-'
                },
                {
                  id: 'deliveries',
                  content: item =>
                    <RadTable
                      columnDefinitions={[
                        {
                          id: 'program',
                          header: 'Program',
                          cell: e => e.program.name
                        },
                        {
                          id: 'tier',
                          header: 'Tier',
                          cell: e => e.tier.name
                        },
                        {
                          id: 'indicator',
                          header: 'Indicator',
                          cell: e => e.serviceDelivery.service.name
                        },
                        {
                          id: 'intervention',
                          header: 'Intervention',
                          cell: e => e.serviceDelivery.name
                        },
                        {
                          id: 'minutes',
                          header: 'Minutes',
                          cell: e => e.minutes
                        }
                      ]}
                      items={item.deliveries}
                      trackBy='id'
                      variant='embedded'
                    />
                },
                {
                  id: 'people',
                  content: item => {
                    return (
                      <>
                        {item.people.length > 12 && <div><b>Students</b> ({item.people.length - (item.blockedPersonCount ?? 0)})</div>}
                        {item.people.length < 12 &&
                          <RadTable
                            columnDefinitions={[
                              {
                                id: 'student',
                                header: 'Student',
                                width: '40%',
                                cell: e => <RadLink href={`/student/${e.id}`}>{e.fullName}</RadLink>
                              },
                              {
                                id: 'notes',
                                header: 'Notes',
                                cell: e => e.SessionPerson.notes ?? '-'
                              }
                            ]}
                            items={item.people.filter((x) => x.firstName != null)}
                            trackBy='id'
                            variant='embedded'
                            wrapLines
                          />}
                        {item.blockedPersonCount &&
                          <RadBox padding={{ top: 's' }}>
                            {`* This session has ${item.blockedPersonCount} ${item.blockedPersonCount > 1 ? 'students' : 'student'} who you do not have permission to view.`}
                          </RadBox>}
                      </>
                    )
                  }
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 }
            ]}
            items={sessions}
            variant='full-page'
            filter={
              <div>
                <RadBox padding={{ bottom: 's' }}>
                  <RadGrid
                    gridDefinition={[
                      { colspan: { default: 12, s: 6, m: 8 } },
                      { colspan: { default: 12, s: 6, m: 4 } }
                    ]}
                  >
                    <RadTextFilter
                      filteringPlaceholder='Search'
                      filteringAriaLabel='Search sessions'
                      filteringText={filteringText}
                      onChange={({ detail }) => setFilteringText(detail.filteringText)}
                      onDelayedChange={({ detail }) => {
                        setSearchText(detail.filteringText)
                        setCurrentPageIndex(1)
                        searchParams.set('page', 1)
                        if (detail.filteringText) {
                          searchParams.set('search', detail.filteringText)
                        } else {
                          searchParams.delete('search')
                        }
                        setSearchParams(searchParams)
                      }}
                    />
                    <RadBox float='right'>
                      <RadPagination
                        currentPageIndex={currentPageIndex}
                        pagesCount={Math.ceil(count / pageLength)}
                        onChange={({ detail }) => {
                          searchParams.set('page', detail.currentPageIndex)
                          setSearchParams(searchParams)
                          setCurrentPageIndex(detail.currentPageIndex)
                        }}
                        ariaLabels={{
                          nextPageLabel: 'Next page',
                          previousPageLabel: 'Previous page',
                          pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                        }}
                      />
                    </RadBox>
                  </RadGrid>
                </RadBox>
                <RadSpaceBetween direction='horizontal' size='s'>
                  <RadSelect
                    selectedOption={selectedSchoolYearOption}
                    onChange={({ detail }) => {
                      if (detail.selectedOption.value !== '') {
                        searchParams.set('schoolYear', detail.selectedOption.value)
                      } else {
                        searchParams.delete('schoolYear')
                      }
                      setSearchParams(searchParams)
                    }}
                    options={schoolYearOptions ?? []}
                    filteringType='auto'
                    enteredTextLabel={value => value}
                    selectedAriaLabel='Selected'
                    placeholder='Select school year'
                  />
                  <RadSpaceBetween direction='horizontal' size='xs'>
                    <RadSpaceBetween direction='horizontal' size='xs'>
                      <RadBox padding={{ top: 'xxs' }}>From:</RadBox>
                      <RadDatePicker
                        onChange={({ detail }) => {
                          if (detail.value !== '' && isValidDate(detail.value)) {
                            searchParams.set('from', detail.value)
                          } else {
                            searchParams.delete('from')
                          }
                          setSearchParams(searchParams)
                        }}
                        value={from}
                        placeholder='From any date'
                        openCalendarAriaLabel={selectedDate =>
                          'Choose from date' + (selectedDate ? `, selected date is ${selectedDate}` : '')}
                      />
                    </RadSpaceBetween>
                    <RadSpaceBetween direction='horizontal' size='xs'>
                      <RadBox padding={{ top: 'xxs' }}>To:</RadBox>
                      <RadDatePicker
                        onChange={({ detail }) => {
                          if (detail.value !== '' && isValidDate(detail.value)) {
                            searchParams.set('to', detail.value)
                          } else {
                            searchParams.delete('to')
                          }
                          setSearchParams(searchParams)
                        }}
                        value={to}
                        placeholder='To any date'
                        openCalendarAriaLabel={selectedDate =>
                          'Choose to date' + (selectedDate ? `, selected date is ${selectedDate}` : '')}
                      />
                    </RadSpaceBetween>
                  </RadSpaceBetween>
                  <RadSelect
                    selectedOption={programOptions.find((x) => x.value === programId)}
                    onChange={({ detail }) => {
                      if (detail.selectedOption.value !== '') {
                        searchParams.set('programId', detail.selectedOption.value)
                      } else {
                        searchParams.delete('programId')
                      }
                      setSearchParams(searchParams)
                    }}
                    options={programOptions}
                    filteringType='auto'
                    enteredTextLabel={value => value}
                    selectedAriaLabel='Selected'
                    placeholder='Select program'
                  />
                  <RadSelect
                    selectedOption={serviceOptions.find((x) => x.value === serviceId)}
                    onChange={({ detail }) => {
                      if (detail.selectedOption.value !== '') {
                        searchParams.set('indicatorId', detail.selectedOption.value)
                      } else {
                        searchParams.delete('indicatorId')
                      }
                      setSearchParams(searchParams)
                    }}
                    options={serviceOptions ?? []}
                    filteringType='auto'
                    enteredTextLabel={value => value}
                    selectedAriaLabel='Selected'
                    placeholder='Select indicator'
                  />
                  <RadSelect
                    ariaRequired
                    selectedOption={tierOptions.find((x) => x.value === (searchParams.get('tier') ?? ''))}
                    onChange={({ detail }) => {
                      if (detail.selectedOption.value !== '') {
                        searchParams.set('tier', detail.selectedOption.value)
                      } else {
                        searchParams.delete('tier')
                      }
                      setSearchParams(searchParams)
                    }}
                    options={tierOptions}
                    enteredTextLabel={value => value}
                    placeholder='Any tier'
                    selectedAriaLabel='Selected'
                  />
                  <RadSelect
                    selectedOption={schoolOptions.find((x) => x.value === schoolId)}
                    onChange={({ detail }) => {
                      if (detail.selectedOption.value !== '') {
                        searchParams.set('schoolId', detail.selectedOption.value)
                      } else {
                        searchParams.delete('schoolId')
                      }
                      setSearchParams(searchParams)
                    }}
                    options={schoolOptions}
                    filteringType='auto'
                    enteredTextLabel={value => value}
                    selectedAriaLabel='Selected'
                    placeholder='Select school'
                  />
                  <RadSelect
                    selectedOption={gradeOptions.find((x) => x.value === grade)}
                    onChange={({ detail }) => {
                      if (detail.selectedOption.value !== '') {
                        searchParams.set('grade', detail.selectedOption.value)
                      } else {
                        searchParams.delete('grade')
                      }
                      setSearchParams(searchParams)
                    }}
                    options={gradeOptions}
                    filteringType='auto'
                    enteredTextLabel={value => value}
                    selectedAriaLabel='Selected'
                    placeholder='Select grade'
                  />
                  <RadSelect
                    selectedOption={selectedUserOption}
                    onChange={({ detail }) => {
                      setSelectedUserOption(detail.selectedOption)
                      if (detail.selectedOption.value !== '' && detail.selectedOption.value !== userInfo.id.toString()) {
                        searchParams.set('administeredBy', detail.selectedOption.value)
                      } else {
                        searchParams.delete('administeredBy')
                      }
                      setSearchParams(searchParams)
                    }}
                    options={userOptions ?? []}
                    filteringType='manual'
                    onLoadItems={({ detail }) => setUserFilterText(detail.filteringText)}
                    enteredTextLabel={value => value}
                    selectedAriaLabel='Selected'
                    placeholder='Select owner'
                    empty={userFilterText ? 'No matches found' : null}
                  />
                </RadSpaceBetween>
              </div>
            }
            empty={
              <RadBox
                margin={{ vertical: 'xs' }}
                textAlign='center'
                color='inherit'
              >
                <RadSpaceBetween size='m'>
                  <b>No sessions found</b>
                </RadSpaceBetween>
              </RadBox>
            }
          />
        }
      />
    )
  }
}
